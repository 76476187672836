.fullContent{
    display: flex;
    flex-wrap:wrap ;
    margin-top: -40px;
    
}
.imgContent{
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 90px;

}
.descContent{
    margin-left: 20px;
    flex: 4;
    margin-top: 160px;
}
#estrela{
    color: rgb(224, 196, 11);
}
.codigoItem{
    display: flex;
    justify-content:space-between;
    align-items: center;
}
.butoomContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 3;
}
#categoria{
    color: #666;
    font-size: 12pt;
    position: relative;
}
.imgDivSingle{
    max-width: 400px;
    min-width: 200px;
    max-height: 400px;
    width: 400px;
    height: 400px;
    border-radius: 5px;
    border: 1px solid #666;
    box-shadow: 1px 1px 1px 3px rgba(6, 6, 6, 0.3);
    margin-top: 10px;
    transform: scale(0.96);
    
}
#photoVendaId{
    max-width: 400px;
    min-width: 200px;
    max-height: 400px;
    width: 400px;
    height: 400px;
    object-fit: cover;
}
.imgDiv:hover{
    transform: scale(1);
    transition: 0.5s;
}
.zoom{
    border: none;
    color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 180px;
    left: 415px;
    background-color: rgba(6, 6, 6, 0.3);
}
h2{
    margin-top: 0px;
    margin-bottom: 0px;
}

.cardBank{
    color: rgb(59, 75, 88);
    font-size: 20pt;
}
.cartoes{
    width: 40%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.outrosProdutos{
    margin-right: 20px;
    color: rgb(10, 52, 241);
}
.outrosProdutos:hover{
    cursor: pointer;
}
.buttonZapDiv{
    margin: 15px;
    width: 200px;
    height: 40px;
    cursor: pointer;
}
.buttonComprar{
    font-size: 16pt;
    color: #fff;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: rgb(16, 31, 66);
    width: 100%;
    height: 40px;
    border: 1px solid rgba(6, 6, 6, 0.3);
    box-shadow: 1px 1px 1px 3px rgba(6, 6, 6, 0.3);
}
.buttonEditar{
    font-size: 16pt;
    color: #fff;
    margin-bottom: 40px;
    margin-top: -80px;
    border-radius: 5px;
    background-color: rgb(210, 241, 12);
    width: 100%;
    height: 40px;
    border: 1px solid rgba(182, 180, 32, 0.3);
    box-shadow: 1px 1px 1px 3px rgba(182, 180, 32, 0.3);
}
.buttonDeletar{
    font-size: 16pt;
    color: #fff;
    margin-bottom: 40px;
    border-radius: 5px;
    background-color: red;
    width: 100%;
    height: 40px;
    border: 1px solid rgba(211, 33, 33, 0.3);
    box-shadow: 1px 1px 1px 3px rgba(211, 33, 33, 0.3);
}

.buttonZap{
    font-size: 16pt;
    margin-top: 40px;
    border-radius: 5px;
    color: #fff;
    background-color: rgb(47, 255, 61);
    width: 100%;
    height: 40px;
    border: 1px solid rgba(6, 6, 6, 0.3);
    box-shadow: 1px 1px 1px 3px rgba(15, 79, 25, 0.3);
}
.fullForm{
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 5px 20px 5px;
    background-color: rgba(19, 55, 153, 0.94);
    border-radius: 10px;
}
.inputFormEditVenda{
    width: 90%;
    height: 40px;
    border: none;
    border-bottom: 3px solid rgb(228, 252, 12);
    padding: 5px;
    font-size: 15pt;
    margin-top: 20px;
}
.inputFormEditVenda:focus{
    outline: none;
}
.editVenda{
    color: #fff;
}
.textareaEdit{
    width: 90%;
    height: 40px;
    border: none;
    border-bottom: 3px solid rgb(228, 252, 12);
    padding: 5px;
    font-size: 15pt;
    margin-top: 20px;
}
.buttonFormEditVenda{
    width: 90%;
    height: 40px;
    border: none;
    border-bottom: 3px solid rgb(228, 252, 12);
    padding: 5px;
    font-size: 15pt;
    margin-top: 20px;
    cursor: pointer;
}
@media(max-width: 913px){
        .fullForm{
            width: 300px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 20px 5px 20px 5px;
            background-color: rgba(19, 55, 153, 0.94);
            border-radius: 10px;
        }
    
}

@media(max-width: 720px){
    .descContent{
        width: 100%;
        margin-top: 20px;
    }
    .fullForm{
            width: 350px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 20px 5px 20px 5px;
            background-color: rgba(19, 55, 153, 0.94);
            border-radius: 10px;
            margin: 0 auto 0 auto;
    }
    
}
@media(max-width: 695px){
    .descContent{
        width: 100%;
        margin-top: 20px;
    }
    
}
@media(max-width: 420px){
    .fullForm{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 20px 5px 20px 5px;
        background-color: rgba(19, 55, 153, 0.94);
        border-radius: 10px;
        margin: 0 auto 0 auto;
}
    
}