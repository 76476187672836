.widthCadastrar{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
}
.formContent{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 10px;
    justify-content: flex-start;
}

.fullinputOportomoni{
    width: 100%;
    height: 40px;
    border-radius: 8px;
    margin: 10px 0px;
}
.ButtonCadastrarOport{
    width: 100%;
    height: 40px;
    color: #fff;
    border: none;
    background-color: rgb(53, 53, 247);
    border-radius: 10px;
    font-weight: 500;
}
.IClassColor{
    color: red;
    font-weight: bold;
}