.termosContent{
    width: 100%;
    height: 100%;
}
.termoSection{
    width: 37%;
    margin: 20px auto 20px auto;
    border:1px solid #999;
    box-shadow: 1px 1px 3px 1px #999;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.termoTitle{
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}
.termoParagrafo{
    width: 100%;
    text-align: justify;
}
.sancoes{
    width: 100%;
    text-align: justify;
    color: #000;
    background-color: rgb(243, 189, 189);
    font-size: 1rem;
    border: 1.5px dashed red;
    box-shadow: 1px 1px 3px 1px red;
    margin-bottom: 20px;
    padding: 10px;

}
.voltar{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(27, 27, 132);
    border: none;
    box-shadow: 1px 1px 3px 1px rgb(10, 10, 109);
    color: #fff;
}
@media(max-width: 1238px){
    .termoSection{
        width: 50%;
        margin: 20px auto 20px auto;
        border:1px solid #999;
        box-shadow: 1px 1px 3px 1px #999;
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
@media(max-width: 800px){
    .termoSection{
        width: 70%;
        margin: 20px auto 20px auto;
        border:1px solid #999;
        box-shadow: 1px 1px 3px 1px #999;
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
@media(max-width: 650px){
    .termoSection{
        width: 90%;
        margin: 20px auto 20px auto;
        border:1px solid #999;
        box-shadow: 1px 1px 3px 1px #999;
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
@media(max-width: 400px){
    .termoSection{
        width: 95%;
        margin: 20px auto 20px auto;
        border:1px solid #999;
        box-shadow: 1px 1px 3px 1px #999;
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}