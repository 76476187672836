/* *{
    font-family: 'Nunito', sans-serif;
    margin: 0; padding: 0;
    box-sizing: border-box;
    outline: none; border: none;
    text-decoration: none;
    transition:  all 0.2s linear;
    text-transform: capitalize;
} */

/* html{
    font-size: 62.5%;
    overflow-x: hidden;
} */

.containerfooter{
    background: linear-gradient(to right, #00093c, #193498);
    color: #fff;
}
.ulMenuFooter{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    list-style: none;
    padding: 0px;
    margin: 0px;
    gap: 10px;
    color: #fff;
}
.linkItemMenuFooter{
    text-decoration: none;
    color: #fff;
    list-style: none;
}

.container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 2rem;
}

.container .title{
    font-size: 3.5rem;
    color: #fff;
    margin-bottom: 3rem;
    text-transform: uppercase;
    text-align: center;
}

.container .products_container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    gap: 2rem;
}

.container .products_container .product{
    text-align: center;
    padding: 3rem 2rem;
    background: #fff;
    box-shadow: 0.5rem 0.5rem rgba(0, 0, 0, 1);
    outline: 1rem solid #ccc;
    outline-offset: -0.5rem;
    cursor: pointer;
}

.container .products_container .product:hover{
    outline: 0.5rem solid orangered;
    outline-offset: 0;
}

.container .products_container .product img{
    height: 20rem;
    border-bottom: 2px solid black;
}

.container .products_container .product:hover img{
    transform: scale(.9);
}

.container .products_container .product h3{
    padding: 5rem 0;
    font-size: 2rem;
    color: #444;
}

.container .products_container .product:hover h3{
    color: orangered;
}

.container .products_container .product .price{
    font-size: 2rem;
    color: #444;
}

.container .products_container .product .price:hover{
    color: orangered;
}

.container .products_container .product .price h4{
    display: flex;
}

.products_preview{
    position: fixed;
    top: 0; left: 0;
    min-height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, .8);
    display: none;
    align-items: center;
    justify-content: center;
}

.products_preview .preview{
    display: none;
    padding: 2rem;
    text-align: center;
    background: #fff;
    position: relative;
    margin: 2rem;
    width: 40rem;
}

.products-preview .preview img{
    height: 30rem;
}

.products_preview .preview.active{
    display: inline-block;
}

.products_preview .preview .fa_times{
    position: absolute;
    top: 1rem; right: 1.5rem;
    cursor: pointer;
    color: #444;
    font-size: 4rem;
}

.products_preview .preview .fa_times:hover{
    transform: rotate(90deg);
}

.products-preview .preview h3{
    color: #444;
    padding: .5rem 0;
    font-size: 2.5rem;
}

.products_preview .preview .stars{
    padding: 1rem 0;
}

.products_preview .preview .stars i{
    font-size: 1.7rem;
    color: #27ae60;
}

.products_preview .preview .stars span{
    color: #999;
}

.products_preview .preview p{
    line-height: 1.5;
    padding: 1rem 0;
    font-size: 1.6rem;
    color: #777;
}

.products_preview .preview .price{
    padding: 1rem 0;
    font-size: 2.5rem;
    color: #27ae60;
}

.products_preview .preview .buttons{
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    margin-top: 0.5rem;
}

.products_preview .preview .buttons a{
    flex: 1 1 16rem;
    padding: 1rem;
    font-size: 1.8rem;
    color: #444;
    border: 1rem solid #444;
    text-decoration: none;
}

.products_preview .preview .buttons a.cart{
    background: #444;
    color: #fff;
}

.products_preview .preview .buttons a.cart:hover{
    background: #111;
}

.products_preview .preview .buttons a.buy:hover{
    background: #444;
    color: #fff;
}

footer{
    background: linear-gradient(to right, #00093c, #2d0b00);
    border-top-left-radius: 100px;
    bottom: 0;
    width: 100%;
}

footer *{
    color: white;
}

.containerfooter{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    padding: 50px 7%;
}

.footer_col{
    width: 22%;
    padding: 0px 2px;
}
.footer_coll{
    width: 30%;
    padding: 0px 2px;
}

.footer_col h2{
    font-optical-sizing: 1.7em;
    text-transform: uppercase;
    margin-bottom: 20px;
}

p.email{
    margin: 30px 0;
}

p.phone{
    font-size: 20px;
    font-weight: 400;
}

.footer_col .text_office{
    margin-bottom: 20px;
}
.underline{
    width: 70px;
    height: 3px;
    position: relative;
    background-color: white;
    margin-top: 5px;
    margin-bottom: 20px;
    border-radius: 3px;
    overflow: hidden;
}

.underline span{
    width: 7px;
    height: 100%;
    position: absolute;
    left: 10px;
    background-color: rgb(63,63,63);
    border-radius: 3px;
    animation: moving 1.5s linear infinite;
}

@keyframes moving {
    0%{
        left: -20px;
    }
    100%{
        left: 100%;
    }
}

.footer_col ul{
    list-style-type: none;
    padding-top: 10px;
}

.footer_col ul li{
    margin: 10px;
}

.footer_col form{
    margin-top: 20px;
    border-bottom: 1px solid white;
    padding-bottom: 10px;
    display: flex;
}

.footer_col form input{
    width: 70%;
    background: transparent;
    border: none;
    outline: none;
    padding-left: 10px;
}

.footer_col form i{
    font-size: 15px;
}

.footer_col .social-icons{
    margin-top: 20px;
}

.footer_col .social_icons i{
    padding: 10px;
    background-color: white;
    color: #00093c;
    border-radius: 50%;
    margin: 5px;
}

.footer_para{
    max-width: 250px;
    text-align: justify;
}

@media (max-width: 650px){
    .footer_col{
        width: 40%;
        padding: 0px 2px;
    }
    .footer_coll{
        width: 40%;
        padding: 0px 2px;
    }
}
@media (max-width: 450px){
    .footer_col{
        width: 80%;
        padding: 0px 2px;
    }
    .footer_coll{
        width: 80%;
        padding: 0px 2px;
    }
    .containerfooter{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        padding: 50px 0px;
    }
    .newH3{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0px 0px;
    }
    .collunDisplay{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ulMenuFooter{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        margin-top: -5px;
        padding-top: 0px;
        justify-content: flex-start;
    }
}