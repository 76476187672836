.ConfirmContent{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
.linkPaginaDeLogin{
    color: #fff;
}
.linkPaginaDeLogin:hover{
    color: aliceblue;
}
.headerConfirm{
    width: 45%;
    height: 50px;
    margin: 40px auto 0px auto;
    border: none;
    border-bottom: 1px solid #666;
    background-color: aliceblue;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.headerConfirmText{
    padding: 0px;
    margin: 0px;
    font-size: 15pt;
}
.bodyConfirm{
    width: 45%;
    height: 110px;
    margin: 0px auto 0px auto;
    border: none;
    background-color: aliceblue;
    background-color: #fff;
    display: flex;
    justify-content: center;
    padding: 25px;
}
.textConfirm{
    text-align: justify;
}
.submitConfirm{
    width: 45%;
    height: 110px;
    margin: 0px auto 0px auto;
    border: none;
    background-color: aliceblue;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.buttonnComp{
    background-color: blue;
    color: aliceblue;
    border: none;
    height: 50px;
    border-radius: 10px;
    font-size: 16pt;
    padding-left: 20px;
    padding-right: 20px;
}
.buttonnComp:hover{
    color: black;
    transition: 1.5s;
    cursor: pointer;
}
@media(max-width: 900px){
    .headerConfirm{
        width: 60%;
        height: 50px;
        margin: 40px auto 0px auto;
        border: none;
        border-bottom: 1px solid #666;
        background-color: aliceblue;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .bodyConfirm{
        width: 60%;
        height: 110px;
        margin: 0px auto 0px auto;
        border: none;
        background-color: aliceblue;
        background-color: #fff;
        display: flex;
        justify-content: center;
        padding: 25px;
    }
    .submitConfirm{
        width: 60%;
        height: 110px;
        margin: 0px auto 0px auto;
        border: none;
        background-color: aliceblue;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}
@media(max-width: 750px){
    .headerConfirm{
        width: 80%;
        height: 50px;
        margin: 40px auto 0px auto;
        border: none;
        border-bottom: 1px solid #666;
        background-color: aliceblue;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .bodyConfirm{
        width: 80%;
        height: 110px;
        margin: 0px auto 0px auto;
        border: none;
        background-color: aliceblue;
        background-color: #fff;
        display: flex;
        justify-content: center;
        padding: 25px;
    }
    .submitConfirm{
        width: 80%;
        height: 110px;
        margin: 0px auto 0px auto;
        border: none;
        background-color: aliceblue;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}
@media(max-width: 550px){
    .headerConfirm{
        width: 90%;
        height: 50px;
        margin: 40px auto 0px auto;
        border: none;
        border-bottom: 1px solid #666;
        background-color: aliceblue;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .bodyConfirm{
        width: 90%;
        height: 110px;
        margin: 0px auto 0px auto;
        border: none;
        background-color: aliceblue;
        background-color: #fff;
        display: flex;
        justify-content: center;
        padding: 25px;
    }
    .submitConfirm{
        width: 90%;
        height: 110px;
        margin: 0px auto 0px auto;
        border: none;
        background-color: aliceblue;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}
@media(max-width: 400px){
    .headerConfirm{
        width: 98%;
        height: 50px;
        margin: 40px auto 0px auto;
        border: none;
        border-bottom: 1px solid #666;
        background-color: aliceblue;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .bodyConfirm{
        width: 98%;
        height: 110px;
        margin: 0px auto 0px auto;
        border: none;
        background-color: aliceblue;
        background-color: #fff;
        display: flex;
        justify-content: center;
        padding: 25px;
    }
    .submitConfirm{
        width: 98%;
        height: 110px;
        margin: 0px auto 0px auto;
        border: none;
        background-color: aliceblue;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}