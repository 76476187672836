.Oportunidade{
    width: 100%;
    margin: 0px;
    padding: 0px;
}
.imgContentOpor{
    width: 100%;
    height: 400px;
    background-image: url("../../../public/image/Oportunidade1.png");
    background-size: cover;
    background-position: center;
}
.fullnewHeaderOpor{
    width: 100%;
    margin: 0px;
    padding: 0px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.healfOpor{
    width: 70%;
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px;
    border: none;
    border-bottom: 3px solid #ddd;
}
.leftTextOpor{
    flex: 6;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: #666;
}
.rightIconOpor{
    flex: 6;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    gap: 30px;
    color: #666;
}
.healfPostOpor{
    width: 70%;
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: none;
    border-bottom: 3px solid #ddd;
    gap: 25px;
}
.ImgOportPostItem{
    width: 100%;
}
.dataOport{
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}
.MesOport{
    font-size: 1.3rem;
    font-weight: 500;
    color: #666666a8;
}
.MesTracoOport{
    border: 3px solid #666666a8;
    width: 30px;
    border-radius: 5px;
}
.imgOportPodt{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 20%;
}
.textOpotPost{
    width: calc(100% - 350px);
    display: flex;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    position: relative;
    height: 100%;
}
.titleOportCard{
    font-size: 1.3rem;
    font-weight: 500;
    text-align: left;
}
.subOportCard{
    font-size: 1.1rem;
    text-align: left;
    font-style: italic;
}
.paragrafOportCard{
    font-size: 1.1rem;
    text-align: left;
}
.tracoOport{
    width: 100%;
    border: 3px solid #ddd;
    border-radius: 5px;
    margin: 20px 0px;
}
.linkOport{
    padding: 10px;
    cursor: pointer;
    position: relative;
}
@media (max-width: 940px){
    .tracoOport{
        display: none;
    }
    .linkOport{
        margin-left: 0px;
        padding-left: 0px;
    }
}
@media (max-width: 810px){
    .healfPostOpor{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .dataOport{
        display: none;
    }
    .textOpotPost{
        width: 100%;
    }
    .linkOport{
        position: relative;
    }
}