.fullContentLogin{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-start;
}
/* ========Imagem======== */
.logoAndTextLogin{
    flex: 6;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background-color: red; */
}
.imagemLogooiiLogin{
    margin-top: 150px;
}
.paragrafoLogoLoginNew{
    width: 70%;
    text-align: center;
    font-size: 1.2rem;
    margin-top: 10px;
}
.paragrafoLogoLogin1New{
    width: 80%;
    text-align: center;
    font-size: 1.2rem;
    margin-top: 10px;
}

/* ========Form======== */
.sectionLoginForm{
    flex: 6;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.LoginH1Login{
    margin-top: 150px;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.formLoginLogin{
    width: 100%;
    display: flex;flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.fastInputLogin{
    width: 70%;
    height: 45px;
    margin-top: 25px;
    border-radius: 5px;
}
.menuSettLogin{
    width: 70%;
    height: 45px;
    margin-top: 25px;
    border: none;
    display: flex;
}
.loginSystem{
    flex: 6;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14pt;
    border: 1px solid rgb(50, 50, 241);
    border-left: none;
    border-top: none;
    cursor: pointer;
    background-color: rgb(50, 50, 241);
}
.system{
    flex: 6;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #666;
    border-left: none;
    border-top: none;
    cursor: pointer;
}
.loginSig{
    flex: 6;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14pt;
    border: 1px solid rgb(50, 50, 241);
    border-left: none;
    border-top: none;
    cursor: pointer;
    background-color: rgb(50, 50, 241);
}
.loginNull{
    flex: 6;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(50, 50, 241);
    border-right: none;
    border-top: none;
    cursor: pointer;
}
.loginNullSys{
    flex: 6;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(50, 50, 241);
    border-left: none;
    border-top: none;
    cursor: pointer;
}
.inLogin{
    border: none;
    border-bottom: 2.5px solid #666;
    padding-left: 10px;
    font: 1.5rem;
}
.criarNovaContaButtonLoginNew{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}
#colorLinkLoginNew{
    color: #000;
    font-weight: 600;
    font-size: 14pt;
}
#colorLinkLoginNew:hover{
    color: blue;
    transition: 0.3s;
}
#colorLinkServerLoginNew{
    color: #666;
    font-weight: 600;
    font-size: 12pt;
}
#colorLinkServerLoginNew:hover{
    color: blue;
    transition: 0.3s;
}
.checkuserRegisterAlertLogin{
    width: 70%;
    height: 45px;
    font-size: 1.2rem;
    margin-top: 20px;
    margin-bottom: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid red;
    color: #fff;
    background-color: rgba(255, 0, 0, 0.384);
}
.buttonEntr{
    color: #fff;
    background-color: rgb(50, 50, 241);
    border: none;
    font-size: 1.2rem;
}

/* ==================response============ */
@media(max-width: 800px){
    .paragrafoLogoLoginNew{
        width: 95%;
        text-align: center;
        font-size: 1rem;
        margin-top: 10px;
    }
    .paragrafoLogoLogin1New{
        width: 80%;
        text-align: center;
        font-size: 1rem;
        margin-top: 10px;
    }
}
@media(max-width: 700px){
    .paragrafoLogoLoginNew,
    .paragrafoLogoLogin1New,
    .LoginH1Login{
        display: none;
    }
    .fullContentLogin{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .logoAndTextLogin{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }
    .imagemLogooiiLogin{
        margin-top: 50px;
    }
    .sectionLoginForm{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: -450px;
    }
    .formLoginLogin{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .fastInputLogin{
        width: 90%;
        height: 45px;
        margin-top: 25px;
        border-radius: 5px;
    }
    .menuSettLogin{
        width: 90%;
        height: 45px;
        margin-top: 25px;
        border: none;
        display: flex;
    }
    .checkuserRegisterAlertLogin{
        width: 90%;
        height: 45px;
        font-size: 1.2rem;
        margin-top: 20px;
        margin-bottom: 5px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid red;
        color: #fff;
        background-color: rgba(255, 0, 0, 0.384);
    }
    .criarNovaContaButtonLoginNew{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }
}
@media(max-width: 700px) and (min-height: 960px){
    .sectionLoginForm{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: -630px;
    }
}
@media(max-width: 700px) and (min-height: 910px){
    .sectionLoginForm{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-top: -580px;
    }
}
@media(max-width: 700px) and (min-height: 880px){
    .sectionLoginForm{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: -530px;
    }
}
@media(max-width: 700px) and (min-height: 850px){
    .sectionLoginForm{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: -480px;
    }
}
@media(max-width: 700px) and (max-height: 780px){
    .sectionLoginForm{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: -380px;
    }
}
@media(max-width: 700px) and (max-height: 720px){
    .sectionLoginForm{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: -330px;
    }
}
@media(max-width: 700px) and (max-height: 660px){
    .sectionLoginForm{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: -280px;
    }
}
@media(max-width: 700px) and (max-height: 610px){
    .sectionLoginForm{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: -230px;
    }
}
@media(max-width: 700px) and (max-height: 565px){
    .sectionLoginForm{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: -180px;
    }
}
@media(max-width: 700px) and (max-height: 520px){
    .sectionLoginForm{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: -130px;
    }
}
@media(max-width: 700px) and (max-height: 475px){
    .sectionLoginForm{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: -80px;
    }
}
@media(max-width: 700px) and (max-height: 425px){
    .sectionLoginForm{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: -10px;
    }
}
@media(max-width: 700px) and (max-height: 400px){
    .sectionLoginForm{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 15px;
    }
}
@media(max-width: 500px){
    .imagemLogooiiLogin{
        margin-top: 60px;
        width: 300px;
    }
}
@media(max-width: 450px){
    .imagemLogooiiLogin{
        margin-top: 80px;
        width: 250px;
    }
}