/* text-align: justify;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical; */

.hpraceAluguelUltemate{
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.Produto{
    font-size: small;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
    border-radius: 3px;
    margin: 10px 10px 20px 10px;
    width: 300px;
    height: 370px;
    border: 1px solid ;
    box-shadow: 1px 1px 1px 3px ;
    transform: scale(0.96);
    object-fit: cover;
    font-family: 'Roboto', sans-serif;
}
.fullFullCard{
    width: 100%;
    position: relative;
    object-fit: contain;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}
.centerCardFFF{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}
.fullFullCardf{
    width: 100%;
    position: relative;
    object-fit: contain;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.AvendaColor{
    color: rgb(104, 248, 104);
}
.CardVendaNewWay{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.cardAluguelnewVendaNova{
    width: 300px;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 1px -1px 13px -3px rgba(26,51,161,0.92);
    -webkit-box-shadow: 1px -1px 13px -3px rgba(26,51,161,0.92);
    -moz-box-shadow: 1px -1px 13px -3px rgba(26,51,161,0.92);
    /* margin-bottom: 20px; */
    margin: 0px 10px 20px 10px;
}
.imagemAluguelSectionNeww{
    width: 300px;
    height: 200px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.imgAluguelCArdAddw{
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 10px;
    /* border-bottom-left-radius: 30px; */
    border-top-right-radius: 10px;
}
.buttonAluguelSectionw{
    width: 300px;
    height: 40px;
}
.buttonAluguelInfoCardw{
    width: 300px;
    height: 40px;
    color: #fff;
    background: linear-gradient(blue, rgba(10, 16, 40, 0.92));
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.descritionAluguelSetionw{
    width: 300px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.Produto:hover{
    transform: scale(1);
    transition: 2s;
    object-fit: cover;
}
.nomePreco{
    width: 100%;
    border-radius: 0 0 3px 3px;
    border-top: 1px solid rgba(110, 110, 110, 0.3);;
    background-color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.imgProduto{
    width: 100%;
    height: 270px;
    object-fit: cover;
}
.imagemCard{
    width: 100%;
    height: 270px;
    border-radius: 3px 3px 0 0;
    object-fit: cover;
}
.valorNome{
    color: rgb(78, 75, 75);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.searchform{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 30px;
    margin-right: 78px;
}
.searchformInportt{
    width: 40%;
    height: 65px;
    border-radius: 25px;
    border-color: #666;
    border: 1px solid #666;
    padding-right: 50px;
    padding-left: 10px;
    font-size: 13pt;
}
.searchformInport:focus{
    outline:  1.5px solid rgb(47, 226, 77);
    color: #666;
}
.searchformButton{
    width: 50px;
    height: 50px;
    border-color: #666;
    text-align: center;
    background-color: rgb(47, 226, 77);
    box-shadow: 1px 1px 1px rgb(25, 240, 61);
    border-radius: 50%;
    border: 1px solid rgb(47, 226, 77);
    margin-left: -28px;
}
.colorSearch{
    color: #fff;
}
.Encontrar{
    width: 100%;
    display: flex;
    justify-content: center;
}

@media (max-width: 1347px){
    .Produto{
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: aliceblue;
        border-radius: 3px;
        margin: 10px 10px 20px 10px;
        width: 240px;
        height: 310px;
        border: 1px solid red;
        box-shadow: 1px 1px 1px 3px red;
    }
    .imagemCard{
        width: 100%;
        height: 260px;
        border-radius: 3px 3px 0 0;
    }
    .valorNome{
        color: rgb(78, 75, 75);
        font-size: 10pt;
    }
    .nomePreco{
        width: 100%;
        border-radius: 0 0 3px 3px;
        border-top: 1px solid rgba(110, 110, 110, 0.3);;
        background-color: #fff;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
}


@media (max-width: 1074px){
    .Produto{
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: aliceblue;
        border-radius: 3px;
        margin: 10px 10px 20px 10px;
        width: 200px;
        height: 270px;
        border: 1px solid rgba(70, 69, 69, 0.30);
        box-shadow: 1px 1px 1px 3px rgba(70, 69, 69, 0.30);
    }
    .nomePreco{
        width: 100%;
        border-radius: 0 0 3px 3px;
        border-top: 1px solid rgba(110, 110, 110, 0.3);;
        background-color: #fff;
        height: 45vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .imagemCard{
        width: 100%;
        height: 220px;
        border-radius: 3px 3px 0 0;
    }
    .valorNome{
        color: rgb(78, 75, 75);
        font-size: 10pt;
    }
}
@media (max-width: 992px){
    .searchform{
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        margin-top: 60px;
        margin-right: 100px;
    }
}

@media (min-width: 946px){
    .Produto{
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: aliceblue;
        border-radius: 3px;
        margin: 10px 10px 20px 10px;
        width: 150px;
        height: 220px;
        border: 1px solid rgba(70, 69, 69, 0.30);
        box-shadow: 1px 1px 1px 3px rgba(70, 69, 69, 0.30);
    }
    .nomePreco{
        width: 100%;
        border-radius: 0 0 3px 3px;
        border-top: 1px solid rgba(110, 110, 110, 0.3);;
        background-color: #fff;
        height: 45vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .imagemCard{
        width: 100%;
        height: 170px;
        border-radius: 3px 3px 0 0;
    }
    .valorNome{
        color: rgb(78, 75, 75);
        font-size: 10pt;
    }
}
@media (max-width: 810px){
    .searchformInportt{
        width: 50%;
        height: 65px;
        border-radius: 25px;
        border-color: #666;
        border: 1px solid #666;
        padding-right: 50px;
        padding-left: 10px;
        font-size: 13pt;
    }
    .Produto{
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: aliceblue;
        border-radius: 3px;
        margin: 10px 10px 20px 10px;
        width: 150px;
        height: 220px;
        border: 1px solid rgba(70, 69, 69, 0.30);
        box-shadow: 1px 1px 1px 3px rgba(70, 69, 69, 0.30);
    }
    .nomePreco{
        width: 100%;
        border-radius: 0 0 3px 3px;
        border-top: 1px solid rgba(110, 110, 110, 0.3);;
        background-color: #fff;
        height: 45vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .imagemCard{
        width: 100%;
        height: 170px;
        border-radius: 3px 3px 0 0;
    }
    .valorNome{
        color: rgb(78, 75, 75);
        font-size: 10pt;
    }
}
@media (max-width: 774px){
    .searchform{
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        margin-top: 20px;
        margin-right: 55px;
    }
    .searchformInportt{
        width: 65%;
        height: 65px;
        border-radius: 25px;
        border-color: #666;
        border: 1px solid #666;
        padding-right: 50px;
        padding-left: 10px;
        font-size: 13pt;
    }
}
@media (max-width: 732px){
    .Produto{
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: aliceblue;
        border-radius: 3px;
        margin: 10px 10px 20px 10px;
        width: 180px;
        height: 250px;
        border: 1px solid rgba(70, 69, 69, 0.30);
        box-shadow: 1px 1px 1px 3px rgba(70, 69, 69, 0.30);
    }
    .nomePreco{
        width: 100%;
        border-radius: 0 0 3px 3px;
        border-top: 1px solid rgba(110, 110, 110, 0.3);;
        background-color: #fff;
        height: 45vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .imagemCard{
        width: 100%;
        height: 200px;
        border-radius: 3px 3px 0 0;
    }
    .valorNome{
        color: rgb(78, 75, 75);
        font-size: 10pt;
    }
}

@media (max-width: 650px){
    .Produto{
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: aliceblue;
        border-radius: 3px;
        margin: 10px 10px 20px 10px;
        width: 240px;
        height: 310px;
        border: 1px solid rgba(70, 69, 69, 0.30);
        box-shadow: 1px 1px 1px 3px rgba(70, 69, 69, 0.30);
    }
    .imagemCard{
        width: 100%;
        height: 260px;
        border-radius: 3px 3px 0 0;
    }
    .valorNome{
        color: rgb(78, 75, 75);
        font-size: 10pt;
    }
}
@media (max-width: 570px){
    .Produto{
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: aliceblue;
        border-radius: 3px;
        margin: 10px 10px 20px 10px;
        width: 120px;
        height: 190px;
        border: 1px solid rgba(70, 69, 69, 0.30);
        box-shadow: 1px 1px 1px 3px rgba(70, 69, 69, 0.30);
    }
    .imagemCard{
        width: 100%;
        height: 140px;
        border-radius: 3px 3px 0 0;
    }
    .valorNome{
        color: rgb(78, 75, 75);
        font-size: 10pt;
    }
}
@media (max-width: 470px){
    .Produto{
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: aliceblue;
        border-radius: 3px;
        margin: 10px 10px 20px 10px;
        width: 170px;
        height: 240px;
        border: 1px solid rgba(70, 69, 69, 0.30);
        box-shadow: 1px 1px 1px 3px rgba(70, 69, 69, 0.30);
    }
    .imagemCard{
        width: 100%;
        height: 190px;
        border-radius: 3px 3px 0 0;
    }
    .valorNome{
        color: rgb(78, 75, 75);
        font-size: 10pt;
    }
}
@media (max-width: 463px){
    .Produto{
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: aliceblue;
        border-radius: 3px;
        margin: 10px 10px 20px 10px;
        width: 180px;
        height: 250px;
        border: 1px solid rgba(70, 69, 69, 0.30);
        box-shadow: 1px 1px 1px 3px rgba(70, 69, 69, 0.30);
    }
    .imagemCard{
        width: 100%;
        height: 200px;
        border-radius: 3px 3px 0 0;
    }
    .valorNome{
        color: rgb(78, 75, 75);
        font-size: 10pt;
    }
    .searchform{
        width: 450px;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        margin-top: 30px;
    }
}
@media (max-width: 463px){
    .Produto{
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: aliceblue;
        border-radius: 3px;
        margin: 10px 10px 20px 10px;
        width: 160px;
        height: 230px;
        border: 1px solid rgba(70, 69, 69, 0.30);
        box-shadow: 1px 1px 1px 3px rgba(70, 69, 69, 0.30);
    }
    .imagemCard{
        width: 100%;
        height: 180px;
        border-radius: 3px 3px 0 0;
    }
    .valorNome{
        color: rgb(78, 75, 75);
        font-size: 10pt;
    }
    .searchform{
        width: 450px;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        margin-top: 30px;
    }
}
@media (max-width: 380px){
    .Produto{
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: aliceblue;
        border-radius: 3px;
        margin: 10px 10px 20px 10px;
        width: 240px;
        height: 310px;
        border: 1px solid rgba(70, 69, 69, 0.30);
        box-shadow: 1px 1px 1px 3px rgba(70, 69, 69, 0.30);
    }
    .imagemCard{
        width: 100%;
        height: 260px;
        border-radius: 3px 3px 0 0;
    }
    .valorNome{
        color: rgb(78, 75, 75);
        font-size: 10pt;
    }
    .searchform{
        width: 450px;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        margin-top: 30px;
        margin-left: -50px;
    }
    .searchformInport{
        width: 80%;
        height: 50px;
        border-radius: 25px;
        border-color: #666;
        border: 1px solid #666;
        padding-right: 50px;
        padding-left: 10px;
        font-size: 13pt;
    }
}
@media (max-width: 330px){
    .Produto{
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: aliceblue;
        border-radius: 3px;
        margin: 10px 10px 20px 30px;
        width: 240px;
        height: 310px;
        border: 1px solid rgba(70, 69, 69, 0.30);
        box-shadow: 1px 1px 1px 3px rgba(70, 69, 69, 0.30);
    }
    .imagemCard{
        width: 100%;
        height: 260px;
        border-radius: 3px 3px 0 0;
    }
    .valorNome{
        color: rgb(78, 75, 75);
        font-size: 10pt;
    }
    .searchform{
        width: 450px;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        margin-top: 30px;
        margin-left: -30px;
    }
    .searchformInport{
        width: 80%;
        height: 50px;
        border-radius: 25px;
        border-color: #666;
        border: 1px solid #666;
        padding-right: 50px;
        padding-left: 10px;
        font-size: 13pt;
    }
}
@media (max-width: 309px){
    .Produto{
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: aliceblue;
        border-radius: 3px;
        margin: 10px 10px 20px 30px;
        width: 240px;
        height: 310px;
        border: 1px solid rgba(70, 69, 69, 0.30);
        box-shadow: 1px 1px 1px 3px rgba(70, 69, 69, 0.30);
    }
    .imagemCard{
        width: 100%;
        height: 260px;
        border-radius: 3px 3px 0 0;
    }
    .valorNome{
        color: rgb(78, 75, 75);
        font-size: 10pt;
    }
    .searchform{
        width: 450px;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        margin-top: 30px;
        margin-left: -15px;
    }
    .searchformInport{
        width: 80%;
        height: 50px;
        border-radius: 25px;
        border-color: #666;
        border: 1px solid #666;
        padding-right: 50px;
        padding-left: 10px;
        font-size: 13pt;
    }
}

@media (max-width: 272px){
    .Produto{
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: aliceblue;
        border-radius: 3px;
        margin: 10px 10px 20px 40px;
        width: 240px;
        height: 310px;
        border: 1px solid rgba(70, 69, 69, 0.30);
        box-shadow: 1px 1px 1px 3px rgba(70, 69, 69, 0.30);
    }
    .imagemCard{
        width: 100%;
        height: 260px;
        border-radius: 3px 3px 0 0;
    }
    .valorNome{
        color: rgb(78, 75, 75);
        font-size: 10pt;
    }
    .searchform{
        width: 400px;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        margin-top: 30px;
        margin-left: 10px;
    }
    .searchformInport{
        width: 80%;
        height: 50px;
        border-radius: 25px;
        border-color: #666;
        border: 1px solid #666;
        padding-right: 50px;
        padding-left: 10px;
        font-size: 13pt;
    }
}