.buttonHeaderAluguelHeaderCadastrar{
    font-size: 20pt;
    color: #666;
    text-align: center;
}
.formCadastrarContent{
    width: 100%;
    padding-bottom: 20px;
}
.imgPhotosHoome{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.inputFotoLabelAlugel{
    display: none;
}
.headerI{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12pt;
    font-family: sans-serif;
    margin-top: 20px;
    margin-bottom: 20px;
}
.sizeAdd{
    font-size: 30pt;
}
.labelFoto{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: aqua;
}
.precoType{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.precoTypeone{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.precoTypeInput{
    width: 35%;
    height: 35px;
    padding-left: 10px;
    font-size: 14pt;
    background-color: #fff;
    color: #666;
    border: none;
    border-bottom: 1px solid #666;
    margin-top: 20px;
}
.precoTypeInputNumber{
    width: 19%;
    height: 35px;
    padding-left: 10px;
    font-size: 12pt;
    background-color: #fff;
    color: #666;
    border: none;
    border-bottom: 1px solid #666;
    margin-top: 20px;
}
.forNewDesc{
    width: 85%;
    max-width: 85%;
    min-width: 85%;
    height: 60px;
    max-height: 60px;
    min-height: 60px;
    padding-left: 10px;
    font-size: 12pt;
    background-color: #fff;
    color: #666;
    border: none;
    border-bottom: 1px solid #666;
    margin-top: 20px;
}
.CadastrarcasaEmAluguel{
    width: 87%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    background-color: #193498;
    /* color: #030b25; */
    color: #fff;
    font-size: 15pt;
    border-radius: 10px;
    
}
.buttonHeaderAluguelHeaderCadastrarRed{
    color: rgb(241, 53, 53);
    margin-top: 200px;
}
.labelFotoObject{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #666;
    box-shadow: 1px 1px 1px rgba(6, 6, 6, 0.3);
}
.canc{
    color: #000;
    font-size: 18px;
    cursor: pointer;
}
.canc:hover{
    font-size: 20px;
    color: #6669;
    transition: 2s;
}