.servicess{
    width: 45%;
    position: relative;
    margin: 35px auto 10px auto;
    background-color: #fff;
}
.liteNewSobre{
    cursor: pointer;
}
.liteNewSobre:hover{
    cursor: pointer;
    color: #193498;
}
.titleNewSobre{
    width: 100%;
    background: -webkit-linear-gradient(#193498, #0b1e65);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
}
.titleSobreText{
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20pt;
    margin: 0px;
}
.paragrafoDesc{
    width: 100%;
    margin-top: 15px;
    position: relative;
    display: flex;
    justify-content: center;
}
.descP{
    width: 90%;
    font-size: 12.5pt;
    background-color: #fff;
    color: #666;
    text-align: justify;
    text-indent: 40px;
}
.participantesTitleNew{
    width: 100%;
    color: #666;
    text-align: center;
}
.userNewSobre{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #999;
}
.imagemNewSobre{
    width: 120px;
    height: 120px;
    border-radius: 10px;
    object-fit: cover;
}
.descNewSobre{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
}
@media(max-width: 991px){
    .servicess{
        width: 55%;
        position: relative;
        margin: 70px auto 10px auto;
        background-color: #fff;
    }
}
@media(max-width: 775px){
    .servicess{
        width: 90%;
        position: relative;
        margin: 30px auto 10px auto;
        background-color: #fff;
    }
}
@media(max-width: 550px){
    .servicess{
        width: 90%;
        position: relative;
        margin: 30px auto 10px auto;
        background-color: #fff;
    }
    .userNewSobre{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid #999;
    }
    .descNewSobre{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 0px;
    }
    .imagemNewSobre{
        width: 140px;
        height: 140px;
        border-radius: 10px;
        object-fit: cover;
    }
    .userNewSobre{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #999;
    }
}