
.fullContentRegister2{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-start;
}

/* ===========Imagem========= */
.logoAndText{
    flex: 6;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.imagemLogooi{
    margin-top: 125px;
}
.paragrafoLogo{
    width: 70%;
    text-align: center;
    font-size: 1.2rem;
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
}
.paragrafoLogo1{
    width: 80%;
    text-align: center;
    font-size: 1.2rem;
    margin-top: -12px;
    font-family: 'Roboto', sans-serif;
}
.criarNovaContaButton{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.criarConta{
    font-weight: 700;
    font-size: 14pt;
    font-family: 'Roboto', sans-serif;

}

/* ===========form========= */
.sectionRegisterNew{
    flex: 6;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.bboRegister{
    margin-top: 125px;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.formRegister{
    width: 100%;
    display: flex;flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.fastInput{
    width: 70%;
    height: 45px;
    margin-top: 25px;
    border-radius: 5px;
}
.fastInputFone{
    width: 70%;
    height: 45px;
    margin-top: 25px;
    display: flex;
    align-items: center;
}
.telMundo{
    width: 100%;
    height: 45px;
    
}
.in{
    border: none;
    border-bottom: 2.5px solid #666;
    padding-left: 10px;
    font: 1.5rem;
}
.criarNovaContaButtonRegister{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}
#colorLinkRegister{
    color: #000;
    font-weight: 600;
    font-size: 14pt;
}
#colorLinkRegister:hover{
    color: blue;
    transition: 0.3s;
}
#colorLinkServerRegister{
    color: #666;
    font-weight: 600;
    font-size: 12pt;
}
#colorLinkServerRegister:hover{
    color: blue;
    transition: 0.3s;
}
.entrarbuttonRegister{
    color: #fff;
    background-color: rgb(50, 50, 241);
    border: none;
    font-size: 1.2rem;
}
.checkuserRegisterAlert{
    width: 70%;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid red;
    background-color: rgba(255, 0, 0, 0.435);
    color: #fff;
    margin-top: 10px;
    font-size: 1.2rem;
    border-radius: 5px;
}
.errRegister2{
    width: 70%;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid #FFD700;
    background-color: #ffd90075;
    color: #fff;
    margin-top: 10px;
    font-size: 1.2rem;
    border-radius: 5px;
}
.errRegister3{
    width: 70%;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    color: #fff;
    font-size: 1.2rem;
    border: 1.5px solid #7FFF00;
    background-color: #80ff005a;
    border-radius: 5px;
}
.errRegister4{
    width: 70%;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    color: #fff;
    font-size: 1.2rem;
    background-color: #0080005f;
    border: 1.5px solid #008000;
    border-radius: 5px;
}
/* ==================response============ */
@media(max-width: 800px){
    .paragrafoLogo{
        width: 95%;
        text-align: center;
        font-size: 1rem;
        margin-top: 10px;
    }
    .paragrafoLogo1{
        width: 80%;
        text-align: center;
        font-size: 1rem;
        margin-top: -10px;
    }
}
@media(max-width: 700px){
    .paragrafoLogo,
    .paragrafoLogo1,
    .bboRegister{
        display: none;
    }
    .fullContentRegister2{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .logoAndText{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }
    .imagemLogooi{
        margin-top: 50px;
    }
    .sectionRegisterNew{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-top: -450px;
    }
    .formRegister{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .fastInput{
        width: 90%;
        height: 45px;
        margin-top: 25px;
        border-radius: 5px;
    }
    .criarNovaContaButtonRegister{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }
    .fastInputFone{
        width: 90%;
        height: 45px;
        margin-top: 25px;
        display: flex;
        align-items: center;
    }
}
@media(max-width: 700px) and (min-height: 960px){
    .sectionRegisterNew{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-top: -630px;
    }
}
@media(max-width: 700px) and (min-height: 910px){
    .sectionRegisterNew{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-top: -580px;
    }
}
@media(max-width: 700px) and (min-height: 880px){
    .sectionRegisterNew{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-top: -530px;
    }
}
@media(max-width: 700px) and (min-height: 850px){
    .sectionRegisterNew{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-top: -480px;
    }
}
@media(max-width: 700px) and (max-height: 780px){
    .sectionRegisterNew{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-top: -380px;
    }
}
@media(max-width: 700px) and (max-height: 720px){
    .sectionRegisterNew{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-top: -330px;
    }
}
@media(max-width: 700px) and (max-height: 660px){
    .sectionRegisterNew{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-top: -280px;
    }
}
@media(max-width: 700px) and (max-height: 610px){
    .sectionRegisterNew{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-top: -230px;
    }
}
@media(max-width: 700px) and (max-height: 565px){
    .sectionRegisterNew{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-top: -180px;
    }
}
@media(max-width: 700px) and (max-height: 520px){
    .sectionRegisterNew{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-top: -130px;
    }
}
@media(max-width: 700px) and (max-height: 475px){
    .sectionRegisterNew{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-top: -80px;
    }
}
@media(max-width: 700px) and (max-height: 425px){
    .sectionRegisterNew{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-top: -10px;
    }
}
@media(max-width: 700px) and (max-height: 400px){
    .sectionRegisterNew{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-top: 15px;
    }
}
@media(max-width: 500px){
    .imagemLogooi{
        margin-top: 60px;
        width: 300px;
    }
}
@media(max-width: 450px){
    .imagemLogooi{
        margin-top: 80px;
        width: 250px;
    }
}